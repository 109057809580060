<template>
  <div style="background: #fff">
    <!-- <el-button type="primary">主要按钮</el-button> -->
    <nav>
      <div class="nav_bar">
        <!-- <div class="logo"> -->
        <!-- <img width="100px" height="100px" src="../../assets/logo.png" alt="立优教育" /> -->
        <img class="logo" :src="this.url.imgUrl + logoImg" alt="立优教育" />
        <!-- </div> -->
        <ul class="nav_middle">
          <li @click="showLine" :class="{ bg_color: !is_pageid }">
            <router-link :to="'/'" :class="{ txt_color: !is_pageid }">首页</router-link>
            <div v-if="!is_pageid" class="line_down"></div>
          </li>
          <li :class="{ bg_color: is_pageid == 2 }">
            <router-link :to="'/course/' + 2" :class="{ txt_color: is_pageid == 2 }">课程</router-link>
            <div v-if="is_pageid == 2" class="line_down"></div>
          </li>
          <li :class="{ bg_color: is_pageid == 3 }">
            <router-link :to="'/audition/' + 3" :class="{ txt_color: is_pageid == 3 }">试听</router-link>
            <div v-if="is_pageid == 3 || auditionInfo_page == 10" class="line_down"></div>
            <!-- <div v-if="this.course_id != ''" class="line_down"></div> -->
          </li>
          <li :class="is_pageid == 11 ? 'bg_color' : ''">
            <router-link :to="'/livelist/' + 11" :class="{ txt_color: is_pageid == 11 }">直播</router-link>
            <div v-if="is_pageid == 11" class="line_down"></div>
            <!-- <div v-if="this.course_id != ''" class="line_down"></div> -->
          </li>
          <li :class="{ bg_color: is_pageid == 4 }">
            <router-link :to="'/teachers/' + 4" :class="{ txt_color: is_pageid == 4 }">师资</router-link>
            <div v-if="is_pageid == 4" class="line_down"></div>
          </li>
          <li :class="{ bg_color: is_pageid == 5 }">
            <router-link :to="'/information/' + 5" :class="{ txt_color: is_pageid == 5 }">资讯</router-link>
            <div v-if="is_pageid == 5" class="line_down"></div>
          </li>
          <!-- <li>
            <router-link
              :to="'/about/' + 6"
              :class="{ txt_color: is_pageid == 6 }"
              >关于我们</router-link
            >
            <div v-if="is_pageid == 6" class="line_down"></div>
          </li>-->
          <!-- <li>
            <router-link
              :to="'/enroll/' + 7"
              :class="{ txt_color: is_pageid == 7 }"
              >咨询报名</router-link
            >
            <div v-if="is_pageid == 7" class="line_down"></div>
          </li>-->
          <li class="hot">
            <router-link :to="'/modelpaper'">模考大赛</router-link>
          </li>
          <li :class="{ bg_color: is_pageid == 8 }">
            <router-link :to="'/personal/' + 8" :class="{ txt_color: is_pageid == 8 }">学习中心</router-link>
            <div v-if="is_pageid == 8" class="line_down"></div>
          </li>
          <li style="padding: 0 22px" @click="goTopic">
            <!-- <router-link
              :to="'/chapter/' + 9"
              :class="{ txt_color: is_pageid == 9 }"
              >题库中心</router-link
            >-->
            <span :class="{ txt_color: is_pageid == 9 }">题库</span>
            <div v-if="is_pageid == 9" class="line_down"></div>
          </li>
          <!-- <li style="padding:0 22px" class="hoverli">
            APP下载
            <div class="hover">
              <a :href="`${url.baseUrl}/help/downlod.html`" target="_blank"
                >立优课堂APP</a
              >
              <a :href="`${url.baseUrl}/help/adownlod.html`" target="_blank"
                >安全工程师刷题宝</a
              >
            </div>
          </li> -->
          <!-- <div class="line_down"></div> -->
        </ul>
        <div class="logreg" v-show="!loginBtn">
          <span @click="goLogin">登录</span>
          <span @click="goreg">免费注册</span>
        </div>
        <div v-show="loginBtn" class="btn">
          <!-- 头像 -->
          <img class="head_img" :src="headerimg" alt="立优教育" />

          <div class="sign_out">
            <div>{{ nickname }}</div>
            <button type="button" round @click="signOut">退出</button>
          </div>

          <!-- </a> -->
        </div>
      </div>
    </nav>
    <AddClass :classshow="classshow" @close="classshow = false" />
    <!-- <div v-if="maskStatus == true">盒子</div> -->
    <div v-if="maskStatus == true">
      <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
        :before-close="handleClose">
        <span class="msg_box">
          <img src="../../assets/img/msg.png" alt="立优教育" />您确定要退出登录吗?
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog title="服务协议和隐私条款" :close-on-click-modal="false" :show-close="false" class="isshow" :visible.sync="isshow"
      width="697px" height="520px">
      <div class="body">
        <div>
          <p>
            欢迎使用立优教育官网。我们非常重视您的个人信息和隐私保护，在您使用"立优教育官网"服务之前，请您务必认真阅读
            <span @click="
              (isshow3 = true),
              (show3url = 'http://liyouedu.cn/user-agreement.html'),
              (show3title = '用户协议')
            ">《用户协议》</span>、
            <span @click="
              (isshow3 = true),
              (show3url = 'http://liyouedu.cn/privacy-policy.html'),
              (show3title = '隐私政策')
            ">《隐私协议》</span>以及
            <span @click="isshow2 = true">{{
                `《${agreement_array.check_blue_font}》`
            }}</span>，并充分理解协议条款内容。我们将严格按照您同意的各项条款使用您的个人信息，以便为您提供更好的服务。
          </p>
          <p style="margin-top: 28px">
            立优教育隐私权政策适用于立优教育提供的软件、网站、服务，包括但不限于适用于电脑、移动智能终端产品及服务。
          </p>
          <p style="margin-top: 10px">
            本隐私权政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。了解这些内容，对于您行使个人权利及保护您的个人信息至关重要。请您在使用立优教育产品或服务前务必认真阅读本政策。
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="nosure" @click="isshow = false">不同意</el-button>
        <el-button class="sure" type="primary" @click="istrue">同意</el-button>
      </span>
    </el-dialog>
    <el-dialog v-if="agreement_array.agreement_list.length > 0" class="show2" title="购课须知" :visible.sync="isshow2"
      width="50%">
      <div class="contract_desc">
        <iframe style="width: 100%; height: 100%" :src="agreement_array.agreement_list[show2_is].agreement_url"
          frameborder="0"></iframe>
      </div>
      <div v-if="agreement_array.agreement_list.length > 1">
        <span slot="footer" class="dialog-footer">
          <el-button @click="show2up">上一篇</el-button>
          <span>当前第{{ show2_is + 1 }}篇/共{{
              agreement_array.agreement_list.length
          }}篇</span>
          <el-button type="primary" @click="show2down">{{
              show2_is == agreement_array.agreement_list.length - 1
                ? "关闭"
                : "下一篇"
          }}</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog class="show2" :title="show3title" :visible.sync="isshow3" width="50%">
      <div class="contract_desc">
        <iframe style="width: 100%; height: 100%" :src="show3url" frameborder="0"></iframe>
      </div>
      <div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" style="opacity: 0"></el-button>
          <el-button type="primary" @click="isshow3 = false">关闭</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
import AddClass from "../../components/addClass";
export default {
  name: "Tabs",
  components: {
    AddClass,
  },
  data() {
    return {
      classshow: false,
      show3title: "",
      isshow3: false,
      show3url: "",
      show2_is: 0, //当前篇
      agreement_array: {
        agreement_list: [
          {
            agreement_url: "",
          },
        ],
      },
      headerimg: '',
      isshow2: false,
      isshow: false,
      logoImg: "/assets/img/logo1.png", //logo
      is_pageid: "", // 当前页面id
      auditionInfo_page: "",
      isToken: 0,
      dialogVisible: false,
      btnText: "登录 | 注册",
      loginBtn: true,
      maskStatus: false,
      nickname: "", //用户昵称
    };
  },
  mounted() { },
  methods: {
    show2up() {
      if (this.show2_is == 0) {
        this.$message.error("已在第一篇");
      } else {
        this.show2_is = this.show2_is - 1;
      }
    },
    show2down() {
      if (this.show2_is == this.agreement_array.agreement_list.length - 1) {
        this.isshow2 = false;
      } else {
        this.show2_is = this.show2_is + 1;
      }
    },
    //同意协议
    istrue() {
      let data = [];
      this.agreement_array.agreement_list.forEach((e) => {
        return data.push(e.id);
      });
      api.save_agreement_status({ id: data.toString() }).then((res) => {
        if (res.data.code == 1) {
          this.$message.success(res.data.msg);
          this.isshow = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getNickname() {
      var _this = this;
      var callback = {
        errorHandler: {
          status_401: (output) => {
            localStorage.removeItem("token");
            _this.loginBtn = false;
          },
        },
      };
      api.center(null, callback).then((res) => {
        // console.log(res)
        let routename = this.$route.name;
        if (parseInt(res.data.code) == 1) {
          this.agreement_array = res.data.data.agreement;
          _this.loginBtn = true;
          this.headerimg = require(`../../assets/img/${res.data.data.avatar == '' ? 'touxiang' : res.data.data.avatar}.png`)
          _this.nickname = res.data.data.nickname;
          if (
            routename == "Index" ||
            routename == "Personal" ||
            routename == "Course" ||
            routename == "Chapter"
          ) {
            if (!res.data.data.subject_id || res.data.data.subject_id == 0) {
              this.classshow = true;
            }
            if (res.data.data.agreement.agreement_list.toString() != "") {
              this.isshow = true;
            }
          }
        }
      });
      // }
    },
    // 去登录页
    goLogin() {
      // window.location.href = "/pc/login.html";
      // this.$router.push({ name: "Login" });
      let routeData = this.$router.resolve({ path: "/login" });
      window.open(routeData.href, "_blank");
    },
    goreg() {
      // window.location.href = "/pc/login.html";
      // this.$router.push({ name: "Login" });
      let routeData = this.$router.resolve({ path: "/register" });
      window.open(routeData.href, "_blank");
    },
    // 退出
    signOut() {
      this.dialogVisible = true;
      this.maskStatus = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$message.success({ duration: 1500, message: "退出成功!" });
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
    // 显示线
    showLine() {
      this.is_pageid = this.$route.params.is_pageid;
    },
    // 去题库中心
    goTopic() {
      this.$router.push({
        name: "Chapter",
        params: { is_pageid: 9, select_id: 1 },
      });
    },
  },
  created() {
    console.log(this.$route.name);
    let a = ["Shopdetail", "Order_step1", "Order_step2", "Order_step3"];
    if (a.includes(this.$route.name)) {
      this.is_pageid = 2;
    } else {
      //当前页面id
      this.is_pageid = this.$route.params.is_pageid;
    }
    this.getNickname();
    var token = localStorage.getItem("token");
    if (token == null) {
      this.loginBtn = false;
    } else {
      this.loginBtn = true;
    }

    // this.auditionInfo_page = localStorage.getItem("natActive");
  },
};
</script>
<style lang="scss" scoped>
a {
  color: #000;
}

nav {
  ::v-deep div {
    white-space: nowrap;
  }
}

nav {
  width: 100%;
  height: 90px;
  white-space: normal !important;
  // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.06);
}

nav .nav_bar {
  width: 1400px;
  height: 100%;
  background: #fff;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 250px;
  // height: 39px;
}

nav .nav_middle {
  // width: 666px;
  margin-left: 46px;
  display: flex;
  // min-width: 840px;
  justify-content: space-around;
  /* background: pink; */
}

nav .nav_middle li {
  // padding: 0 22px;
  font-size: 18px;
  font-family: PingFang SC;
  color: #222222;
  line-height: 90px;
  text-align: center;
  cursor: pointer;
}

nav .nav_middle li:hover {
  background: rgba(62, 126, 255, 0.1);
}

nav .nav_middle li a:hover {
  color: #3e7eff;
  font-weight: 600;
}

nav .nav_middle li span:hover {
  color: #3e7eff;
}

.txt_color {
  color: #3e7eff !important;
  font-weight: 600;
}

.nav_line {
  width: 22px;
  height: 3px;
  background: #3e7eff;
  border-radius: 1px;
  margin: 8px auto 0;
}

.nav_middle a {
  /* position: relative; */
  padding: 0 25px;
  cursor: pointer;
  color: #333;
  display: block;
  margin: 0 auto;
}

.msg_box {
  display: flex;
  font-size: 20px;
}

.msg_box img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.line_down {
  width: 22px;
  height: 3px;
  background: #3e7eff;
  border-radius: 1px;
  margin: -20px auto 0;
}

.bg_color {
  background: rgba(62, 126, 255, 0.1);
}

.btn {
  margin-left: 60px;
  display: flex;
  width: 142px;
  align-items: center;
}

.btn .head_img {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.btn .login_btn {
  width: 100px;
  height: 32px;
  border: 2px solid #3e7eff;
  border-radius: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #3e7eff;
  background: #fff;
  margin-left: 6px;
  cursor: pointer;
}

.sign_out {
  margin-left: 8px;
  cursor: pointer;
}

.sign_out div {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #3e7eff;
  margin-bottom: -4px;
}

.sign_out button {
  width: 70px;
  height: 18px;
  border: 1px solid #999999;
  border-radius: 8px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  background: #fff;
  line-height: 16px;
  margin-top: -5px;
  cursor: pointer;
}

.hoverli {
  position: relative;
}

.hoverli:hover {
  color: #3e7eff;
}

.hoverli:hover .hover {
  height: 88px;
}

.hoverli .hover {
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  width: 200%;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  z-index: 10;
  right: -50%;
  line-height: 1;
}

.hoverli .hover a {
  width: 100%;
  font-size: 14px;
  position: relative;
  display: block;
  text-align: center;
  padding: 15px 0px;
  background: #fff;
}

.hoverli .hover a::after {
  content: "";
  display: block;
  width: 80%;
  height: 1px;
  background: #eee;
  position: absolute;
  bottom: 0;
  right: 10%;
}

::v-deep .show2 .el-dialog__body {
  padding-bottom: 10px;
}

::v-deep .show2 .dialog-footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .contract_desc {
  width: 100%;
  height: 60vh;
}

::v-deep .isshow {
  .el-dialog__footer {
    padding: 45px 0 30px;

    .nosure {
      width: 184px;
      padding: 0;
      height: 44px;
    }

    .sure {
      width: 184px;
      padding: 0;
      height: 44px;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button+.el-button {
      margin-left: 80px;
    }
  }

  .el-dialog {
    .el-dialog__header {
      background: #eee;
      padding: 20px;

      .el-dialog__title {
        font-size: 20px;
        font-family: SourceHanSansCN;
        font-weight: bold;
      }
    }

    overflow: hidden;
    border-radius: 20px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .body {
    p {
      margin-top: 45px;
      padding: 0 76px;
      font-size: 16px;
      line-height: 26px;
      color: #333;
      font-weight: normal;
    }

    span {
      color: #409eff;
      cursor: pointer;
      font-weight: normal;
    }

    .el-checkbox__label {
      color: #666 !important;
    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #666;
    }
  }
}

.hot {
  position: relative;
}

.hot::after {
  content: "";
  display: block;
  width: 16px;
  height: 19px;
  background: url(../../assets/img/hot2.png) no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  top: 20px;
  right: 15px;
}

.logreg {
  margin-left: 65px;
}

.logreg span {
  font-size: 18px;
  color: #3e7eff;
  cursor: pointer;
}

.logreg span:last-child {
  padding: 12px 29px;
  background: #3e7eff;
  color: #fff;
  border-radius: 30px;
  margin-left: 30px;
}
</style>

<template>
  <div id="app">
    <!-- 二维码 -->
    <div class="code_box">
      <span>手机做题</span>
      <div>
        扫码下载
        <p>APP</p>
        &nbsp;&nbsp;&nbsp;随时随地刷题
      </div>
      <div class="line"></div>
      <div class="code_img">
        <img :src="code" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      code: "", // 二维码
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.getCode();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取二维码
    getCode() {
      api.download_qrcode().then((res) => {
        // console.log("二维码", res);
        if (parseInt(res.data.code) == 1) {
          this.code = res.data.data.qrcode;
        }
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
// 二维码
.code_box {
  width: 310px;
  height: 354px;
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 21px 0 8px;
  }
  div:nth-child(2) {
    display: flex;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    p {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
    }
  }
  .line {
    width: 260px;
    height: 1px;
    // border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 1px;
    margin: 20px 0 37px;
  }
  .code_img {
    width: 189px;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../assets/img/kuang.png") no-repeat;
    background-size: 189px 188px;
    img {
      width: 162px;
      height: 162px;
    }
  }
}
</style>

import http from './http'
import url from '@/env/env'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "http://www.liyouedu.cn"
let resquest = url.apiUrl

// get请求
export default {
    // APP下载二维码
    download_qrcode(params) {
        return http.get(`${resquest}/api/website/download_qrcode`, params)
    },
    // 师资 
    faculty_list_new(params) {
        return http.get(`${resquest}/api/website/faculty_list_new`, params)
    },
    // 首页接口
    index(params) {
        return http.get(`${resquest}/api/website/index`, params)
    },
    // 课程列表接口
    courses(params) {
        return http.get(`${resquest}/api/courses`, params)
    },
    // 课程分类
    course_cate(params) {
        return http.get(`${resquest}/api/courses/course_cate`, params)
    },
    // 课程分类
    category_list(params) {
        return http.get(`${resquest}/api/courses/category_list`, params)
    },
    // 直播详情
    live_detail(params) {
        return http.get(`${resquest}/api/live/live_detail`, params)
    },
    // 直播课程分类二级
    my_live_chapter_cate(params) {
        return http.get(`${resquest}/api/live/my_live_chapter_cate`, params)
    },
    //更多直播
    live_list(params) {
        return http.get(`${resquest}/api/live/my_live_list`, params)
    },
    // 课程详情
    course_detail(params) {
        return http.get(`${resquest}/api/courses/detail`, params)
    },
    // 师资标签
    tag_list(params) {
        return http.get(`${resquest}/api/website/tag_list`, params)
    },
    // 师资力量
    faculty_list(params) {
        return http.get(`${resquest}/api/website/faculty_list`, params)
    },
    // 资讯列表
    article(params) {
        return http.get(`${resquest}/api/article/index_v1`, params)
    },
    // 资讯列表详情页
    detail(params) {
        return http.get(`${resquest}/api/article/detail`, params)
    },
    // 咨询留言
    add_message(params) {
        return http.post(`${resquest}/api/website/add_message`, params)
    },
    // 登录接口
    login(params) {
        return http.get(`${resquest}/api/user/login`, params)
    },
    // 发送短信
    send(params) {
        return http.post(`${resquest}/api/sms/send`, params)
    },
    // 用户注册
    register(params) {
        return http.post(`${resquest}/api/user/register`, params)
    },
    // 修改密码
    resetpwd(params) {
        return http.post(`${resquest}/api/user/resetpwd`, params)
    },
    // 科目列表
    subject(params) {
        return http.get(`${resquest}/api/subject/index`, params)
    },
    //首页直播
    index_live(params) {
        return http.get(`${resquest}/api/website/index_live`, params)
    },
    //每日一练二级接口
    my_course_chapcate(params) {
        return http.get(`${resquest}/api/orders_edge/my_course_chapcate`, params)
    },
    //图片列表
    pic(params) {
        return http.get(`${resquest}/api/pic`, params)
    },
    // 我的课程
    mycourse(params) {
        return http.get(`${resquest}/api/orders_edge/mycourse`, params)
    },
    // 课程章节
    course_chapter(params) {
        return http.get(`${resquest}/api/orders_edge/course_chapter`, params)
    },
    // 章节详情（章节视频）接口
    chapter_detail(params) {
        return http.get(`${resquest}/api/orders_edge/chapter_detail_v1`, params)
    },
    // 保利威
    play_safe(params) {
        return http.get(`${resquest}/api/polyv/play_safe`, params)
    },
    // 学习记录
    video_record(params) {
        return http.post(`${resquest}/api/video_record/add`, params)
    },
    // 学习记录v1
    video_recordv1(params) {
        return http.get(`${resquest}/api/video_record/add_v1`, params)
    },
    // 个人中心
    center(params) {
        var callback = {}
        if (arguments.length >= 2) {
            callback = arguments[1]
        }
        return http.post(`${resquest}/api/user/center`, params, callback)
    },
    // 提交手机号
    landing_site(params) {
        return http.get(`${resquest}/api/landing_site/register`, params)
    },
    // 我的班主任
    myteacher(params) {
        return http.get(`${resquest}/api/user/myteacher`, params)
    },
    // 题库接口
    // ------------------------------------------------------------------------
    // 题库公告
    topic_notice(params) {
        return http.get(`${resquest}/api/notice`, params)
    },
    // 做题数上报
    question_count(params) {
        return http.post(`${resquest}/api/ex/add_question_count`, params)
    },
    // 选择章节分类
    chapter_category(params) {
        return http.get(`${resquest}/api/ex/chapter_category`, params)
    },
    // 章节分类列表
    chapter_list(params) {
        return http.get(`${resquest}/api/ex/chapter_list`, params)
    },
    // 章节分类列表
    chapter_question_list(params) {
        return http.get(`${resquest}/api/ex_study/chapter_question_list`, params)
    },
    // 每日一练
    random(params) {
        return http.get(`${resquest}/api/ex_study/random`, params)
    },
    // 历年真题 / 模拟试题
    ex_papers(params) {
        return http.get(`${resquest}/api/ex_papers`, params)
    },
    // 交卷 / 保存进度
    submit(params) {
        return http.post(`${resquest}/api/papers_record/submit`, params)
    },
    // 试卷
    test_papers(params) {
        return http.get(`${resquest}/api/ex_papers/detail`, params)
    },
    // 做题记录 已做试卷列表
    papers_record(params) {
        return http.get(`${resquest}/api/papers_record/papers_list`, params)
    },
    // 做题记录 试卷历史做题记录
    record_list(params) {
        return http.get(`${resquest}/api/papers_record/record_list`, params)
    },
    // 做题记录详情/成绩单
    record_detail(params) {
        return http.get(`${resquest}/api/papers_record/detail`, params)
    },
    // 错题本 各题型对应的数量
    qtype_num(params) {
        return http.get(`${resquest}/api/ex_wrongnote/qtype_num`, params)
    },
    // 错题本-题型对应的试题列表
    ex_wrongnote(params) {
        return http.get(`${resquest}/api/ex_wrongnote`, params)
    },
    // 添加收藏
    add_collect(params) {
        return http.get(`${resquest}/api/ex_collect/add`, params)
    },
    // 删除收藏
    del_collect(params) {
        return http.get(`${resquest}/api/ex_collect/del`, params)
    },
    // 收藏数量
    collect_num(params) {
        return http.get(`${resquest}/api/ex_collect/qtype_num`, params)
    },
    // 收藏列表
    ex_collect(params) {
        return http.get(`${resquest}/api/ex_collect/index`, params)
    },
    // 收藏列表
    correction_add(params) {
        return http.post(`${resquest}/api/ex_study/correction_add`, params)
    },

    // 添加错题本
    addex_wrongnote(params) {
        return http.post(`${resquest}/api/ex_wrongnote/add`, params)
    },

    // 修改资料
    save_agreement_info(params) {
        return http.post(`${resquest}/api/user/save_agreement_info`, params)
    },
    // 购课协议列表
    agreement_list(params) {
        return http.get(`${resquest}/api/entrance/my_agreement_list`, params)

    },
    // 购课协议列表
    save_agreement_status(params) {
        return http.get(`${resquest}/api/orders/save_agreement_status`, params)
    },

    // 模考大赛试卷列表
    mock_list(params) {
        return http.get(`${resquest}/api/ex/mock_list`, params)
    },

    // 模考大赛项目分类
    chapter_category_list(params) {
        return http.get(`${resquest}/api/ex_mocks/chapter_category_list`, params)
    },
    //模考大赛试卷详情
    ex_mocksdetail(params) {
        return http.get(`${resquest}/api/ex_mocks/detail`, params)
    },

    //新版首页
    index_v1(params) {
        return http.get(`${resquest}/api/website/index_v1`, params)
    },
    //客服坐席
    service_seat(params) {
        return http.get(`${resquest}/api/website/service_seat`, params)
    },
    //直播场次列表
    live_field_list(params) {
        return http.get(`${resquest}/api/live/live_field_list`, params)
    },
    //PC商品详情
    pc_detail(params) {
        return http.get(`${resquest}/api/goods/detail`, params)
    },
    //提交订单课程信息
    buy_goods_data(params) {
        return http.get(`${resquest}/api/goods/buy_goods_data`, params)
    },
    //提交订单
    submit_order(params) {
        return http.post(`${resquest}/api/orders_edge/submit_order`, params)
    },
    //pc 微信 支付宝支付
    web_pay(params) {
        return http.get(`${resquest}/api/orders/web_pay`, params)
    },
    //支付成功信息
    pay_success_data(params) {
        return http.get(`${resquest}/api/orders_edge/pay_success_data`, params)
    },
    //我的订单
    my_order_list(params) {
        return http.get(`${resquest}/api/orders_edge/my_order_list`, params)
    },
    //订单详情
    order_info_v1(params) {
        return http.get(`${resquest}/api/orders/order_info_v1`, params)
    },
    //订单详情
    course_goods_map(params) {
        return http.get(`${resquest}/api/courses/course_goods_map`, params)
    },
    //PC-我的课程(获得项目和科目)
    get_user_project(params) {
        return http.post(`${resquest}/api/user/get_user_project`, params)
    },
    //我的课程_v1
    chapter_list_v1(params) {
        return http.get(`${resquest}/api/orders_edge/chapter_list_v1`, params)
    },
    //设置用户的科目
    set_user_subject(params) {
        return http.get(`${resquest}/api/user/set_user_subject`, params)
    },
    //用户已购课程分类项目
    my_course_cate(params) {
        return http.get(`${resquest}/api/orders_edge/my_course_cate`, params)
    },
    //用户推荐课程
    user_recommend_goods(params) {
        return http.get(`${resquest}/api/orders_edge/user_recommend_goods`, params)
    },
    //直播讲义
    live_file_book(params) {
        return http.get(`${resquest}/api/book/live_file_book`, params)
    },
    //根据章节获得讲义
    course_chapter_book(params) {
        return http.get(`${resquest}/api/book/course_chapter_book`, params)
    },
    //上传图片
    uploadimg(params) {
        return http.post(`${resquest}/api/common/upload`, params)
    },
    //重读审核列表
    restudy_apply_list(params) {
        return http.get(`${resquest}/api/restudy/restudy_apply_list`, params)
    },
    //获得重读课程
    restudy_course_list(params) {
        return http.get(`${resquest}/api/restudy/restudy_course_list`, params)
    },
    //申请重读
    restudy_course_add(params) {
        return http.get(`${resquest}/api/restudy/restudy_course_add`, params)
    },
}
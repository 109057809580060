

let a = {
    ////正式环境
    baseUrl: "https://www.liyouedu.cn",
    imgUrl: "https://www.liyouedu.cn",
    apiUrl: "https://liyouedu.cn",
    wapUrl: "https://m.liyouedu.cn"
}
let b = {
    ////测试
    baseUrl: "https://www.dev.liyouedu.cn",
    imgUrl: "https://www.dev.liyouedu.cn",
    apiUrl: "https://api.dev.liyouedu.cn",
    wapUrl: "https://m.dev.liyouedu.cn",
    scriptUrl: "http://crm.test.com"
}
// console.log(process.env)
let c = process.env.VUE_APP_TITLE === 'development' ? b : a
// let c = a;
export default c;
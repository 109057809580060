<template>
  <div id="app">
    <!-- 列表 -->
    <div class="menu_box">
      <img
        v-for="item in menu"
        :key="item.id"
        :src="select_id == item.id ? item.select_icon : item.icon"
        alt=""
        @click="goPage(item)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        // 菜单
        {
          // 章节练习
          id: 1,
          icon: require("../assets/img/lianxi_normal.png"),
          select_icon: require("../assets/img/lianxi.png"),
          name: "Chapter", //路由名字
        },
        {
          // 每日一练
          id: 2,
          icon: require("../assets/img/yilian_normal.png"),
          select_icon: require("../assets/img/yilian.png"),
          name: "Everyday", //路由名字
        },
        {
          // 历年真题
          id: 3,
          icon: require("../assets/img/zhenti_normal.png"),
          select_icon: require("../assets/img/zhenti.png"),
          name: "TrueTopic", //路由名字
        },
        {
          // 模拟试题
          id: 4,
          icon: require("../assets/img/moni_normal.png"),
          select_icon: require("../assets/img/moni.png"),
          name: "Simulation", //路由名字
        },
        {
          // 做题记录
          id: 5,
          icon: require("../assets/img/jilu_normal.png"),
          select_icon: require("../assets/img/jilu.png"),
          name: "Record", //路由名字
        },
        {
          // 错题本
          id: 6,
          icon: require("../assets/img/cuoti_normal.png"),
          select_icon: require("../assets/img/cuoti.png"),
          name: "WrongQuestion", //路由名字
        },
        {
          // 试题收藏
          id: 7,
          icon: require("../assets/img/shoucang_normal.png"),
          select_icon: require("../assets/img/shoucang.png"),
          name: "Collect", //路由名字
        },
      ],
      select_id: 1,
    };
  },

  mounted() {},

  methods: {
    goPage(el) {
      console.log("el", el);
      // if ([4, 5, 6].includes(el.id)) {
      //   this.$message.error({
      //     duration: 2000,
      //     message: "该模块暂未开通，敬请期待...",
      //   });
      // } else {
      //   console.log(el.id);
        this.$router.push({
          name: el.name,
          params: { is_pageid: 9, select_id: el.id },
        });
      // }
      this.menu.icon = el.select_icon;
    },
  },
  created() {
    this.select_id = this.$route.params.select_id;
  },
};
</script>

<style lang="scss" scoped>
//   菜单栏
.menu_box {
  width: 1200px;
  height: 130px;
  border-radius: 6px 6px 0 0;
  background: #f9fcff;
  margin: auto;
  display: flex;
  justify-content: space-between;
  img {
    width: 170px;
    height: 130px;
    cursor: pointer;
  }
}
</style>

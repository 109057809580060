<template>
  <div id="app">
    <div class="content_box">
      <div class="slide_nav_box">
        <div class="slide_el">
          <div class="myClass">我的课程</div>
          <div
            class="target_el"
            v-for="item in class_list"
            :key="item.id"
            @click="goCard(item)"
            :class="{ target_el_choose: choose_nav == item.id }"
            @mouseover="cutImg(item, $event)"
            @mouseleave="resetImg(item, $event)"
          >
            <img
              id="none"
              class="icon_img"
              :src="item.icon_hover"
              alt=""
              v-if="item.id == choose_nav"
            />
            <img class="icon_img" :src="item.icon" alt="" v-else />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="slide_el">
          <div class="myClass">我的题库</div>
          <div
            class="target_el"
            v-for="item in topic_list"
            :key="item.id"
            @click="goCard(item)"
            :class="{ target_el_choose: choose_nav == item.id }"
            @mouseover="cutImg(item, $event)"
            @mouseleave="resetImg(item, $event)"
          >
            <img
              id="none"
              class="icon_img"
              :src="item.icon_hover"
              alt=""
              v-if="item.id == choose_nav"
            />
            <img class="icon_img" :src="item.icon" alt="" v-else />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="slide_el">
          <div class="myClass">个人管理</div>
          <div
            class="target_el"
            v-for="item in personal_list"
            :key="item.id"
            @click="goCard(item)"
            :class="{ target_el_choose: choose_nav == item.id }"
          >
            <img
              id="none"
              class="icon_img"
              :src="item.icon_hover"
              alt=""
              v-if="item.id == choose_nav"
            />
            <img class="icon_img" :src="item.icon" alt="" v-else />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="slide_el">
          <div class="myClass">其他</div>
          <div
            class="target_el"
            v-for="item in other_list"
            :key="item.id"
            @click="goCard(item)"
            :class="{ target_el_choose: choose_nav == item.id }"
          >
            <img
              id="none"
              class="icon_img"
              :src="item.icon_hover"
              alt=""
              v-if="item.id == choose_nav"
            />
            <img class="icon_img" :src="item.icon" alt="" v-else />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      choose_nav: 1, // 选中导航
      choose: false,
      // 我的课程
      class_list: [
        {
          id: 1,
          name: "开始听课",
          icon: require("../assets/img/xuexi_normal.png"),
          icon1: require("../assets/img/xuexi_normal.png"),
          icon_hover: require("../assets/img/xuexi_hover.png"),
          routerName: "",
        },
      ],
      //   我的题库
      topic_list: [
        {
          id: 2,
          name: "开始做题",
          icon: require("../assets/img/zuoti_normal.png"),
          icon1: require("../assets/img/zuoti_normal.png"),
          icon_hover: require("../assets/img/zuoti_hover.png"),
          routerName: "",
        },
        {
          id: 3,
          name: "错题本",
          icon: require("../assets/img/cuoti_normal (1).png"),
          icon1: require("../assets/img/cuoti_normal (1).png"),
          icon_hover: require("../assets/img/cuoti_hover.png"),
          routerName: "",
        },
        {
          id: 4,
          name: "我的收藏",
          icon: require("../assets/img/shoucang_normal (1).png"),
          icon1: require("../assets/img/shoucang_normal (1).png"),
          icon_hover: require("../assets/img/shoucang_hover.png"),
          routerName: "",
        },
      ],
      // 个人管理
      personal_list: [
        {
          id: 5,
          name: "我的钱包",
          icon: require("../assets/img/qianbao_normal.png"),
          icon1: require("../assets/img/qianbao_normal.png"),
          icon_hover: require("../assets/img/qianbao_hover.png"),
          routerName: "",
        },
        {
          id: 6,
          name: "我的班主任",
          icon: require("../assets/img/banzhuren_normal.png"),
          icon1: require("../assets/img/banzhuren_normal.png"),
          icon_hover: require("../assets/img/banzhuren_hover.png"),
          routerName: "",
        },
      ],
      //   其他
      other_list: [
        {
          id: 7,
          name: "用户协议",
          icon: require("../assets/img/xieyi_normal.png"),
          icon1: require("../assets/img/xieyi_normal.png"),
          icon_hover: require("../assets/img/xieyi_hover.png"),
          routerName: "",
        },
        {
          id: 8,
          name: "隐私政策",
          icon: require("../assets/img/yinsi_normal.png"),
          icon1: require("../assets/img/yinsi_normal.png"),
          icon_hover: require("../assets/img/yinsi_hover.png"),
          routerName: "",
        },
      ],
    };
  },
  computed: {},
  beforeCreate() {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    //   鼠标进入切换图片
    cutImg(item, e) {
      item.icon = item.icon_hover;
    },
    // 鼠标离开恢复图标
    resetImg(item, e) {
      item.icon = item.icon1;
    },
    // 点击切换模块
    goCard(item) {
      this.choose = true;
      this.choose_nav = item.id;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.content_box {
  width: 1200px;
  margin: auto;
  background: #f5f5f5;
  .slide_nav_box {
    width: 230px;
    height: 484px;
    background: #fff;
    margin-top: 30px;
    .slide_el {
      //   height: 40px;
      // 我的课程
      .myClass {
        height: 36px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        padding-left: 36px;
      }
      .target_el {
        height: 40px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        border-left: 3px solid #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon_img {
          width: 17px;
          height: 15px;
          margin: 0 8px 0 32px;
        }
        .icon_img2 {
          background: url("../assets/img/zuoti_normal.png") no-repeat;
          background-size: 15px 15px;
        }
        .icon1 {
          background: url("../assets/img/xuexi_hover.png") no-repeat;
          background-size: 15px 15px;
        }
      }
      .target_el_choose {
        color: #3e7eff;
        border-left: 3px solid #3e7eff;
        // background: rgba(62, 126, 255, .5);
        background: #ebf2ff;
      }
      .target_el:hover {
        color: #3e7eff;
        // .icon_img {
        //   background: url("../assets/img/2 (2).png") no-repeat;
        //   background-size: 15px 15px;
        // }
        // .icon_img2 {
        //   background: url("../assets/img/xuexi_hover.png") no-repeat;
        //   background-size: 15px 15px;
        // }
      }
    }
  }
}
</style>
